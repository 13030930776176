import React from "react";

const LoadingSpinner = () => {
  return (
    <button className="loading-spinner flex-center" type="button" disabled>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Loading...
    </button>
  );
};

export default LoadingSpinner;
