import React, { useState, useEffect, useRef } from "react";
import Calendar from "../Calendar";
import { TermList } from "../TermList";
import { TermReservationForm } from "./TermReservationForm";
import useAdminCheck from "../shared/AdminCheck";
import useFetchTerms from "../shared/FetchTerms";
import Message from "../shared/Message";
import LoadingSpinner from "../shared/LoadingSpinner";
import config from "../../config";

const Reservations = (props) => {
  const { preFilledPatient, onTermSave } = props;
  const [currentDate, setCurrentDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      new Date().getHours()
    )
  );
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [activeDate, setActiveDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      new Date().getHours()
    )
  );
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = useAdminCheck();
  const { terms, refresh } = useFetchTerms(currentDate, isAdmin);
  const [termCounts, setTermCounts] = useState([]);
  const [termTypes, setTermTypes] = useState([]);
  const [savedTermId, setSavedTermId] = useState(null);
  const termReservationFormRef = useRef(null);
  const [showAddToGoogleCalendarModal, setShowAddToGoogleCalendarModal] =
    useState(false);

  useEffect(() => {
    const tomorrow = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      new Date().getHours()
    );
    setCurrentDate(tomorrow);
    setActiveDate(tomorrow);
    fetchTermTypes();
    fetchNearestAvailableTerm();
  }, []);

  useEffect(() => {
    if (selectedTermId && termReservationFormRef.current) {
      termReservationFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedTermId]);

  const fetchNearestAvailableTerm = async () => {
    try {
      const response = await fetch(`${config.apiEndpoint}/api/Terms/nearestAvailableTerm`, {
        headers: {
          // Include any necessary headers, such as authorization headers
        },
      });

      if (!response.ok) throw new Error('Failed to fetch nearest available term');

      const nearestTermDate = await response.json();
      const nearestDate = new Date(nearestTermDate);
      // Update state to reflect the nearest available term date
      setCurrentDate(nearestDate);
      setActiveDate(nearestDate);
      fetchTermCounts(nearestDate.getFullYear(), nearestDate.getMonth() + 1);
      // Additionally, fetch term counts for the month of the nearest available term
    } catch (error) {
      console.error("Error fetching nearest available term:", error);
    }
  };

  const fetchTermCounts = async (year, month) => {
    try {
      const response = await fetch(`${config.apiEndpoint}/api/Terms/byMonth/${year}/${month}`, {
        headers: {
          // Add your auth headers if required
        },
      });
      if (!response.ok) throw new Error('Failed to fetch term counts');
      const data = await response.json();
      // Assuming you have a state in the parent component to hold term counts
      setTermCounts(data);
    } catch (error) {
      console.error("Error fetching term counts:", error);
    }
  };


  const fetchTermTypes = () => {
    fetch(`${config.apiEndpoint}/api/TermTypes`, {
      headers: {
        // If authentication is required, add your auth headers here
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        setTermTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching term types:", error);
      });
  };
  const handleAddToGoogleCalendar = () => {
    const apiUrl = `${config.apiEndpoint}/api/Terms/${savedTermId}`;
    const token = localStorage.getItem("token");

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((term) => {
        if (term) {
          const startDate = new Date(term.date);
          const endDate = new Date(
            startDate.getTime() + term.termType?.duration * 60000
          );
          const details = {
            action: "TEMPLATE",
            text: "MKMasaze | " + term.termType?.name,
            dates: `${startDate
              .toISOString()
              .replace(/-|:|\.\d\d\d/g, "")}/${endDate
                .toISOString()
                .replace(/-|:|\.\d\d\d/g, "")}`,
            details: term.adminNotes,
            location: "", // Enter location here if any
          };

          const url = `https://www.google.com/calendar/render?${new URLSearchParams(
            details
          )}`;

          window.open(url, "_blank");
        }

        setSelectedTermId(null);
        setSavedTermId(null);

      })
      .catch((error) => {
        console.log("Error fetching term:", error);
      });
  };

  const handleDateChange = (date) => {
    setIsLoading(true);
    setCurrentDate(date);
    setSelectedTermId(null);
    setActiveDate(date);
    setIsLoading(false);
  };

  const handleTermClick = (termId) => {
    setSelectedTermId(termId);
  };

  const handleSaveTerm = async (editedTerm) => {
    setIsLoading(true);
    try {
      const utcDate = new Date(editedTerm.date).toISOString();
      const skLocaleDateTime = new Date(editedTerm.date).toLocaleString(
        "sk-SK",
        {
          hour: "numeric",
          minute: "numeric",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );

      const token = localStorage.getItem("token");
      const response = await fetch(
        `${config.apiEndpoint}/api/Terms/${editedTerm.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...editedTerm, date: utcDate }),
        }
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      if (editedTerm.termType !== null) {
        setShowAddToGoogleCalendarModal(true);
      }

      if (editedTerm.termType !== null) {
        setShowAddToGoogleCalendarModal(true);
        setSavedTermId(editedTerm.id); // Save the id here
      }
      // Refresh the terms after successfully saving a term
      refresh();

      setMessage({
        type: "success",
        text: `Boli ste prihlásený na termín - ${skLocaleDateTime} (${editedTerm.termType?.name}).`,
      });
      if (onTermSave) {
        onTermSave();
      }
      setSelectedTermId(null);
    } catch (error) {
      console.error("Error saving term:", error);
      setMessage({
        type: "danger",
        text: `Error saving term: ${error.message}`,
      });
    } finally {
      setIsLoading(false);
    }

  };

  const handleDeleteTerm = async (termId) => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${config.apiEndpoint}/api/Terms/${termId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      setMessage({
        type: "success",
        text: "Termín bol vymazaný.",
      });

      setSelectedTermId(null);
    } catch (error) {
      console.error("Error deleting term:", error);
      setMessage({
        type: "danger",
        text: `Error deleting term: ${error.message}`,
      });
    }

    setIsLoading(false);
  };

  const handleCancelEdit = () => {
    setSelectedTermId(null);
  };

  const handleCloseMessage = () => {
    setMessage(null);
  };

  return (
    <div className="d-flex gap-3 flex-column">
      <div>
        <h2 className="me-3">Rezervácie</h2>
      </div>
      <div className="rescal">
        <div className="calendar flex-1">
          <Calendar
            value={currentDate}
            onDateChange={handleDateChange}
            activeDate={activeDate}
            termCounts={termCounts}
            fetchTermCounts={fetchTermCounts}
          />
        </div>
        <div className="flex-1">
          <TermList
            date={currentDate}
            terms={terms}
            termTypes={termTypes}
            selectedTermId={selectedTermId}
            onTermClick={handleTermClick}
            isAdmin={isAdmin}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Message
        message={message}
        onClose={handleCloseMessage}
        handleAddToGoogleCalendar={
          showAddToGoogleCalendarModal === false
            ? null
            : () => handleAddToGoogleCalendar()
        }
      />

      {selectedTermId && (
        <div ref={termReservationFormRef}>
          <TermReservationForm
            term={terms.find((term) => term.id === selectedTermId)}
            preFilledPatient={preFilledPatient}
            onSave={handleSaveTerm}
            onCancel={handleCancelEdit}
            onDelete={handleDeleteTerm}
          />
        </div>
      )}
    </div>
  );
};

export default Reservations;
