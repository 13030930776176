import React, { useEffect, useState } from "react";
import axios from "axios";
import configApi from "../../config";
import TermStats from "./TermStats";
import PatientStats from "./PatientStats";
import OccupiedTermsChart from "./TermsPrice";


const MainStatsComponent = () => {
  const [terms, setTerms] = useState([]);
  const [patients, setPatients] = useState([]);
  const [activeTab, setActiveTab] = useState("termStats"); // Default active tab

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    fetchTerms();
    fetchPatients();
  }, []);

  const fetchTerms = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Terms`,
        config
      );
      setTerms(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Patients`,
        config
      );
      setPatients(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <nav>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "termStats" ? "active" : ""}`}
              onClick={() => handleTabClick("termStats")}
            >
              Term Stats
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${
                activeTab === "termsPriceStats" ? "active" : ""
              }`}
              onClick={() => handleTabClick("termsPriceStats")}
            >
              Occupied Terms Chart
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${
                activeTab === "patientStats" ? "active" : ""
              }`}
              onClick={() => handleTabClick("patientStats")}
            >
              Patient Stats
            </button>
          </li>
        </ul>
      </nav>
      {activeTab === "termStats" && <TermStats terms={terms} />}
      {activeTab === "termsPriceStats" && (
        <OccupiedTermsChart terms={terms} /> // Pass terms data as prop
      )}
      {activeTab === "patientStats" && <PatientStats patients={patients} />}
    </div>
  );
};

export default MainStatsComponent;
