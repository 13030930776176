import React, { Component } from 'react';
import config from "../../config";

function toLocalTime(date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

class TermReservationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: props.term || null,
            patientId: props.term?.patientId || '',
            termTypeId: props.term?.termTypeId || '',
            phoneNumber: props.term?.phoneNumber || '',
            email: props.term?.email || '',
            firstName: props.term?.firstName || '',
            lastName: props.term?.lastName || '',
            userNotes: props.term?.userNotes || '',
            adminNotes: props.term?.adminNotes || '',
            termTypes: [],  // Will hold the TermTypes fetched from the API
            step: props.step,  // Start at step 1
            selectedTime: toLocalTime(props.term?.date ? new Date(props.term.date) : new Date()),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.term !== prevProps.term) {
            this.setState({
                term: this.props.term,
                selectedTime: toLocalTime(this.props.term?.date ? new Date(this.props.term.date) : new Date()),
                step: 1
            });
        }
    }



    componentDidMount() {
        // Fetch TermTypes from the API when the component mounts
        fetch(`${config.apiEndpoint}/api/TermTypes`)
            .then(response => response.json())
            .then(data => this.setState({ termTypes: data }))
            .catch(error => console.error('Error:', error));
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // Here you would handle form submission, e.g. send data to the server.
        // After successful submission, you could also clear the form or give feedback to the user.
    }

    handleTermTypeChange = (event) => {
        this.setState({
            termTypeId: event.target.value,
        });
    }

    handleTimeChange = (event) => {
        const chosenTime = new Date(`1970-01-01T${event.target.value}:00`); // Convert chosen time to a Date object
        const lastSelectableTime = new Date(`1970-01-01T${this.calculateLastSelectableStartTime()}:00`);

        if (chosenTime.getTime() > lastSelectableTime.getTime()) {
            return;
        }

        this.setState({ selectedTime: event.target.value });
    }



    onBack = (event) => {
        this.setState({ step: this.state.step - 1 })
    }

    onNext = () => {
        this.setState({ step: this.state.step + 1 });
    }

    calculateEndTime = () => {
        const { selectedTime, termTypes, termTypeId } = this.state;

        const selectedTermType = termTypes.find(t => Number(t.id) === Number(termTypeId));

        if (!selectedTermType || !selectedTime) return '';

        const [hours, minutes] = selectedTime.split(':').map(Number);
        const startDate = new Date();
        startDate.setHours(hours, minutes);

        const endDate = new Date(startDate.getTime() + selectedTermType.duration * 60000);

        return `${toLocalTime(startDate)} - ${toLocalTime(endDate)}`;
    }

    calculateLastSelectableStartTime = () => {
        const { term, termTypes, termTypeId } = this.state;
        const selectedTermType = termTypes.find(t => Number(t.id) === Number(termTypeId));

        if (!selectedTermType || !term?.date) return '';

        const termStartDate = new Date(new Date(term.date).toLocaleString());
        const termEndDate = new Date(termStartDate.getTime() + term.duration * 60000);

        const lastSelectableStartTime = new Date(termEndDate.getTime() - selectedTermType.duration * 60000);
        return toLocalTime(lastSelectableStartTime);
    }

    render() {
        if (!this.props.term) {
            return null;
        }
        // Get the date from the term.
        const termDate = new Date(this.props.term.date);
        // Calculate the start and end times for the term.
        const startTime = toLocalTime(termDate);
        const selectedTermType = this.state.termTypes.find(
            t => Number(t.id) === Number(this.state.termTypeId)
        );
        const lastSelectableStartTime = this.calculateLastSelectableStartTime();
        return (
            <div className="container mt-4">
                <form onSubmit={this.handleSubmit}>
                    {this.state.step === 1 && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">Term Type:</label>
                                <select name="termTypeId" onChange={this.handleTermTypeChange} value={this.state.termTypeId} className="form-control">
                                    <option key={0} value="">Select a Term Type...</option>
                                    {this.state.termTypes.map(termType => (
                                        <option key={termType.id} value={termType.id}>#{termType.id} {termType.name} ({termType.price}€ | {termType.duration}min)</option>
                                    ))}
                                </select>
                            </div>
                            {this.state.step !== 1 || (this.state.step === 1 && this.state.termTypeId) ? (
                                <button type="button" className="btn btn-primary" onClick={this.onNext}>Next</button>
                            ) : null}
                        </>
                    )}
                    {this.state.step === 2 && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">Select Time:</label>
                                <input
                                    type="time"
                                    name="selectedTime"
                                    min={startTime}
                                    max={this.calculateLastSelectableStartTime()}
                                    step="300"
                                    value={this.state.selectedTime}
                                    onChange={this.handleTimeChange}
                                    className="form-control"
                                />


                                <small className="form-text">
                                    Duration: {this.calculateEndTime()}
                                </small>
                            </div>
                            <button type="button" className="btn btn-secondary" onClick={this.onBack}>Back</button>
                            <button type="button" className="btn btn-primary" onClick={this.onNext}>Next</button>
                        </>
                    )}
                    {this.state.step === 3 && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">Phone Number:</label>
                                <input type="text" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleChange} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email:</label>
                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">First Name:</label>
                                <input type="text" name="firstName" value={this.state.firstName} onChange={this.handleChange} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Last Name:</label>
                                <input type="text" name="lastName" value={this.state.lastName} onChange={this.handleChange} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">User Notes:</label>
                                <textarea name="userNotes" value={this.state.userNotes} onChange={this.handleChange} className="form-control"></textarea>
                            </div>
                            <button type="button" className="btn btn-secondary" onClick={this.onBack}>Back</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </>
                    )}
                    <button type="button" className="btn btn-secondary" onClick={this.props.onCancel}>Cancel</button>
                </form>
                {selectedTermType && (
                    <div className="alert alert-info mb-3">
                        {selectedTermType.name && <><strong>Term Type:</strong> {selectedTermType.name} <br /></>}
                        {this.state.selectedTime && this.calculateEndTime().split(' - ')[1] && <><strong>Time:</strong> {this.state.selectedTime} - {this.calculateEndTime().split(' - ')[1]} <br /></>}
                        {selectedTermType.price && <><strong>Price:</strong> {selectedTermType.price}€ <br /></>}
                        {this.state.phoneNumber && <><strong>Phone Number:</strong> {this.state.phoneNumber} <br /></>}
                        {this.state.email && <><strong>Email:</strong> {this.state.email} <br /></>}
                        {this.state.firstName && <><strong>First Name:</strong> {this.state.firstName} <br /></>}
                        {this.state.lastName && <><strong>Last Name:</strong> {this.state.lastName} <br /></>}
                        {this.state.userNotes && <><strong>User Notes:</strong> {this.state.userNotes} <br /></>}
                    </div>
                )}
            </div>
        );
    }
}

export default TermReservationForm;
