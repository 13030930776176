import { useEffect, useState } from 'react';
import config from "../../config";

const useFetchTerms = (date, isAdmin) => {
  const [terms, setTerms] = useState([]);
  const [refreshTerms, setRefreshTerms] = useState(false);

  useEffect(() => {
    const fetchDate = date.toISOString();
    
    const apiUrl = `${config.apiEndpoint}/api/Terms/bydate/${fetchDate}`;
    const token = localStorage.getItem("token");

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTerms(data);
        setRefreshTerms(false); // Reset the refresh state
      })
      .catch((error) => {
        console.log("Error fetching terms:", error);
      });
  }, [date, isAdmin, refreshTerms]); // Add refreshTerms dependency

  return { terms, refresh: () => setRefreshTerms(true) }; // Return refresh function
};

export default useFetchTerms;
