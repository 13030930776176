import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const TermStats = ({ terms }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (terms.length > 0) {
      createChart();
    }
  }, [terms]);

  const createChart = () => {
    const termsByMonth = {};
    terms.forEach((term) => {
      const month = new Date(term.date).toLocaleString("default", {
        month: "long",
      });

      if (!termsByMonth[month]) {
        termsByMonth[month] = {
          isOccupied: 0,
          notOccupied: 0,
        };
      }

      if (term.isOccupied) {
        termsByMonth[month].isOccupied++;
      } else {
        termsByMonth[month].notOccupied++;
      }
    });

    const months = Object.keys(termsByMonth);
    const isOccupiedData = months.map(
      (month) => termsByMonth[month].isOccupied
    );
    const notOccupiedData = months.map(
      (month) => termsByMonth[month].notOccupied
    );

    const chartData = {
      labels: months,
      datasets: [
        {
          label: "Occupied Terms",
          data: isOccupiedData,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
        {
          label: "Not Occupied Terms",
          data: notOccupiedData,
          backgroundColor: "rgba(192, 75, 75, 0.6)",
        },
      ],
    };

    const chartOptions = {
      scales: {
        y: {
          beginAtZero: true,
          precision: 0,
        },
      },
    };

    const chartConfig = {
      type: "bar",
      data: chartData,
      options: chartOptions,
    };

    if (chartRef.current) {
      new Chart(chartRef.current, chartConfig);
    }
  };

  return (
    <div>
      <h3>Term Statistics</h3>
      <p>Occupied Terms: {terms.filter((term) => term.isOccupied).length}</p>
      <p>
        Present Terms: {terms.filter((term) => term.wasPresent === true).length}
      </p>
      <p>
        Earned money: €
        {terms.reduce((total, term) => {
          if (term.wasPresent && term.termType) {
            return total + term.termType.price;
          }
          return total;
        }, 0)}
      </p>

      <h3>Terms by Month</h3>
      <canvas ref={chartRef} width={400} height={100}></canvas>
    </div>
  );
};

export default TermStats;
