import React, { Component } from "react";
import config from "../../config";
import axios from 'axios';
import LoadingSpinner from "../shared/LoadingSpinner";
import { duration } from "moment";
import Login from "../auth/Login";

export class TermReservationForm extends Component {
  constructor(props) {
    super(props);

    const { term, preFilledPatient } = props;

    this.state = {
      isLoading: false,
      user: null,
      isLoadingRole: true,
      editedTerm: {
        id: term.id,
        date: term.date,
        isOccupied: preFilledPatient ? true : term.isOccupied || false,
        isHidden: term.isHidden || false,
        isReminderSent: term.isReminderSent || false,
        phoneNumber: preFilledPatient ? preFilledPatient.phoneNumber : term.phoneNumber || "",
        email: preFilledPatient ? preFilledPatient.email : term.email || "",
        firstName: preFilledPatient ? preFilledPatient.firstName : term.firstName || "",
        lastName: preFilledPatient ? preFilledPatient.lastName : term.lastName || "",
        termType: term.termType || null,
        userNotes: term.userNotes || "",
        adminNotes: preFilledPatient ? "Objednal administrator!" : term.adminNotes || "",
        patientNotes: term.patientNotes || "",
        patient: preFilledPatient ? preFilledPatient : term.patient || null,
        wasPresent: term.wasPresent || false,
        duration: term.duration || 60,
      },
      termTypes: [],
      patients: [],
      isAdmin: false,
      showAlert: false,
      validated: false,
      token: localStorage.getItem("token"),
      gdprAgreed: false,
    };
  }

  componentDidMount() {
    this.fetchTermTypes();
    this.checkAdminRole();
    if (this.state.isAdmin) {
      this.fetchPatients();
    }
    if (this.state.token)
      this.fetchUserProfile();
  }

  fetchUserProfile = async () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem('token');
    const configHeader = {
      headers: { Authorization: `Bearer ${token}` }
    };
    try {
      const response = await axios.get(`${config.apiEndpoint}/api/account/profile`, configHeader);
      this.setState({ user: response.data });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if ((prevState.user !== this.state.user) && !this.state.isAdmin) {
      this.setState({
        editedTerm: {
          ...this.state.editedTerm,
          phoneNumber: this.state.user.phoneNumber || "",
          email: this.state.user.email || "",
          firstName: this.state.user.firstName || "",
          lastName: this.state.user.lastName || "",
        }
      });
    }
    if (prevProps.term.id !== this.props.term.id) {
      const { term } = this.props;
      const { user } = this.state;
      const admin = this.state.isAdmin;
      const loadingRole = this.state.isLoadingRole;

      this.setState({
        editedTerm: {
          id: term.id,
          date: term.date,
          isOccupied: term.isOccupied,
          isHidden: term.isHidden,
          phoneNumber: (user && !admin && !loadingRole) ? user.phoneNumber || "" : term.phoneNumber || "",
          email: (user && !admin && !loadingRole) ? user.email || "" : term.email || "",
          firstName: (user && !admin && !loadingRole) ? user.firstName || "" : term.firstName || "",
          lastName: (user && !admin && !loadingRole) ? user.lastName || "" : term.lastName || "",
          termType: term.termType || null,
          userNotes: term.userNotes || "",
          adminNotes: term.adminNotes || "",
          isReminderSent: term.isReminderSent || false, // provide a default value
          patient: term.patient || null,
          duration: term.duration || null,
        },
        validated: false,
      });
    }
  }

  isUserAuthenticated = () => {
    return !!localStorage.getItem("token");
  }

  checkAdminRole = () => {
    const apiUrl = `${config.apiEndpoint}/api/account/CheckAdminRole`;

    if (this.state.token) {
      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ isAdmin: data.isAdmin, isLoadingRole: false });
          if (data.isAdmin)
            this.fetchPatients();
        })
        .catch((error) => {
          console.log("Error checking admin role:", error);
          this.setState({ isLoadingRole: false });
        });
    } else {
      this.setState({ isAdmin: false });
    }
  };

  fetchPatients = () => {
    const apiUrl = `${config.apiEndpoint}/api/Patients`;
    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ patients: data });
      })
      .catch((error) => {
        console.log("Error fetching patients:", error);
      });
  };

  fetchTermTypes = () => {
    const apiUrl = `${config.apiEndpoint}/api/TermTypes`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ termTypes: data });
      })
      .catch((error) => {
        console.log("Error fetching term types:", error);
      });
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      this.setState((prevState) => ({
        editedTerm: {
          ...prevState.editedTerm,
          [name]: checked,
        },
      }));
    } else if (name === "termType") {
      const selectedTermType = this.state.termTypes.find(
        (termType) => termType.id === parseInt(value)
      );
      this.setState((prevState) => ({
        editedTerm: {
          ...prevState.editedTerm,
          termType: selectedTermType,
        },
      }));
    } else if (name === "patient") {
      const selectedPatient = this.state.patients.find(
        (patient) => patient.id === parseInt(value)
      );
      this.setState((prevState) => ({
        editedTerm: {
          ...prevState.editedTerm,
          patient: selectedPatient,
        },
      }));
    } else if (name === "wasPresent") {
      this.setState((prevState) => ({
        editedTerm: {
          ...prevState.editedTerm,
          wasPresent: checked,
        },
      }));
    } else if (name === "duration") {
      this.setState((prevState) => ({
        editedTerm: {
          ...prevState.editedTerm,
          duration: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        editedTerm: {
          ...prevState.editedTerm,
          [name]: value,
        },
      }));
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { editedTerm, isAdmin } = this.state;
    if (!this.isUserAuthenticated() && !this.state.gdprAgreed) {
      alert("Musíte súhlasiť so spracovaním osobných údajov v súlade s GDPR.");
      return;
    }
    if (!isAdmin && !editedTerm.termType) {
      alert("Term Type is required for non-admin users.");
      return;
    }

    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.stopPropagation();
    }

    this.setState({ validated: true, showAlert: form.checkValidity() });
  };

  handleConfirmSave = () => {
    this.setState({ showAlert: false });
    const { editedTerm } = this.state;
    const termToSave = {
      ...editedTerm,
      isReminderSent: editedTerm.isReminderSent || false,
    };
    this.props.onSave(termToSave);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  formatDateForInput = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(adjustedDate.getMinutes() + 120);
    const formattedDate = adjustedDate.toISOString().slice(0, 16);
    return formattedDate;
  };

  formatDate = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = new Date(date).toLocaleString("sk-SK", options);

    return `${formattedDate}`;
  };

  handleClear = () => {
    this.setState((prevState) => ({
      editedTerm: {
        ...prevState.editedTerm,
        phoneNumber: "",
        email: "",
        firstName: "",
        lastName: "",
        termType: null,
        isOccupied: false,
        isHidden: false,
        isReminderSent: false,
        userNotes: "",
        adminNotes: "",
      },
    }));
  };

  handleDelete = () => {
    const { editedTerm } = this.state;
    // Call the onDelete prop with the editedTerm.id to delete the term
    this.props.onDelete(editedTerm.id);
  };

  render() {
    const { editedTerm, termTypes, isAdmin, showAlert, validated, patients } =
      this.state;
    const formattedDate = this.formatDate(this.props.term.date ? this.props.term.date : null);
    return (
      <div className={`term-form ${showAlert ? "bg-warning-opacity-50" : ""}`}>
        <div className="d-flex justify-content-between">
          <h4>{showAlert ? "" : "Detail termínu"}</h4>
          {!showAlert ? <p className="text-muted">
            {formattedDate}{" "}
            {editedTerm.termType ? " | " + editedTerm.termType.name : null}
          </p> : null}
        </div>
        {this.state.isLoading ? <LoadingSpinner /> : null}
        {!showAlert ? (
          <form
            onSubmit={this.handleSubmit}
            className={validated ? "was-validated" : ""}
          >
            {isAdmin ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Dátum:
                    </label>
                    <input
                      type="datetime-local"
                      id="date"
                      name="date"
                      className="form-control"
                      value={this.formatDateForInput(editedTerm.date)}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col col-md-3 mb-3">
                    <label htmlFor="isOccupied" className="form-label">
                      Je obsadené:
                    </label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="isOccupied"
                        name="isOccupied"
                        className="form-check-input"
                        checked={editedTerm.isOccupied}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="isOccupied">
                        Áno
                      </label>
                    </div>
                  </div>
                  <div className="col col-md-3 mb-3">
                    <label htmlFor="isHidden" className="form-label">
                      Skrytý termín:
                    </label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="isHidden"
                        name="isHidden"
                        className="form-check-input"
                        checked={editedTerm.isHidden}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="isHidden">
                        Áno
                      </label>
                    </div>
                  </div>

                </div>
                <div className="row">
                  {editedTerm.termType === null && (
                    <div className="col-md-6 mb-3">
                      <label htmlFor="duration" className="form-label">
                        Dĺžka termínu (v minútach)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="duration"
                        name="duration"
                        value={editedTerm.duration}
                        onChange={this.handleChange}
                      />

                    </div>
                  )}

                  <div className="col col-md-3 mb-3">
                    <label htmlFor="isReminderSent" className="form-label">
                      Email s upozornením poslaný:
                    </label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="isReminderSent"
                        name="isReminderSent"
                        className="form-check-input"
                        checked={editedTerm.isReminderSent}
                        onChange={this.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isReminderSent"
                      >
                        Áno
                      </label>
                    </div>
                  </div>
                  <div className="col col-md-3 mb-3">
                    <label htmlFor="wasPresent" className="form-label">
                      Bol prítomný:
                    </label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="wasPresent"
                        name="wasPresent"
                        className="form-check-input"
                        checked={editedTerm.wasPresent || false}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="wasPresent">
                        Áno
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    Krstné meno:
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className={`form-control ${validated && !editedTerm.firstName ? "is-invalid" : ""
                      }`}
                    value={editedTerm.firstName}
                    onChange={this.handleChange}
                    required={!isAdmin}
                  />
                  {validated && !editedTerm.firstName && (
                    <div className="invalid-feedback">
                      Krstné meno je povinné pro neadministrátorské uživatele.
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Priezvisko:
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className={`form-control ${validated && !editedTerm.lastName ? "is-invalid" : ""
                      }`}
                    value={editedTerm.lastName}
                    onChange={this.handleChange}
                    required={!isAdmin}
                  />
                  {validated && !editedTerm.lastName && (
                    <div className="invalid-feedback">
                      Priezvisko je povinné pro neadministrátorské uživatele.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Telefónne číslo:
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    className={`form-control ${validated && !editedTerm.phoneNumber ? "is-invalid" : ""
                      }`}
                    value={editedTerm.phoneNumber}
                    onChange={this.handleChange}
                    required={!isAdmin}
                  />
                  {validated && !editedTerm.phoneNumber && (
                    <div className="invalid-feedback">
                      Telefónne číslo je povinné pro neadministrátorské
                      uživatele.
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`form-control ${validated && !editedTerm.email ? "is-invalid" : ""
                      }`}
                    value={editedTerm.email}
                    onChange={this.handleChange}
                    required={!isAdmin}
                  />
                  {validated && !editedTerm.email && (
                    <div className="invalid-feedback">
                      Email je povinný pro neadministrátorské uživatele.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {isAdmin ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="patient" className="form-label">
                      Pacient:
                    </label>
                    <select
                      id="patient"
                      name="patient"
                      className="form-select"
                      value={editedTerm.patient ? editedTerm.patient.id : ""}
                      onChange={this.handleChange}
                    >
                      <option value="">Vyberte pacienta</option>
                      {patients.map((patient) => (
                        <option key={patient.id} value={patient.id}>
                          {patient.firstName} {patient.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="termType" className="form-label">
                    Typ termínu:
                  </label>
                  <select
                    id="termType"
                    name="termType"
                    className={`form-select ${validated && !editedTerm.termType ? "is-invalid" : ""
                      }`}
                    value={editedTerm.termType ? editedTerm.termType.id : ""}
                    onChange={this.handleChange}
                    required={!isAdmin}
                  >
                    <option value="">Vyberte typ termínu</option>
                    {termTypes
                      .filter(termType => termType.duration <= editedTerm.duration)
                      .map((termType) => (
                        <option key={termType.id} value={termType.id}>
                          {termType.name} ({termType.price}€ | {termType.duration} min.)
                        </option>
                      ))}
                  </select>
                  {validated && !editedTerm.termType && (
                    <div className="invalid-feedback">
                      Typ termínu je povinný!
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isAdmin && (
              <div className="mb-3">
                <label htmlFor="adminNotes" className="form-label">
                  Poznámky administrátora:
                </label>
                <textarea
                  id="adminNotes"
                  name="adminNotes"
                  className="form-control"
                  value={editedTerm.adminNotes}
                  onChange={this.handleChange}
                />
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="userNotes" className="form-label">
                Poznámka k termínu:
              </label>
              <textarea
                id="userNotes"
                name="userNotes"
                className="form-control"
                value={editedTerm.userNotes}
                onChange={this.handleChange}
                placeholder="Čokoľkvek, čo považujete za vhodné napísať k termínu."
              />
            </div>
            {!this.isUserAuthenticated() && (<div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="gdprAgreement"
                checked={this.state.gdprAgreed}
                onChange={() => this.setState({ gdprAgreed: !this.state.gdprAgreed })}
              />
              <label className="form-check-label" htmlFor="gdprAgreement">
                Súhlasím so spracovaním mojich osobných údajov v súlade s GDPR.
              </label>
            </div>)}
            <div className="d-flex justify-content-md-end gap-2">
              {isAdmin && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.handleDelete}
                >
                  Vymazať
                </button>
              )}
              {isAdmin ? (
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.handleClear}
                >
                  Vyčistiť
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.handleCancel}
              >
                Zrušiť
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={isAdmin ? this.handleConfirmSave : null}
              >
                {isAdmin ? "Uložiť" : "Prihlásiť sa na termín"}
              </button>



            </div>
          </form>
        ) : null}

        {showAlert && !isAdmin && (
          <div className="term-confirmation">
            <div className="mb-3">
              <div className="d-flex row gap-3">
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{editedTerm.termType.name}</h4>
                  <span>Typ termínu</span>
                </div>
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{formattedDate}</h4>
                  <span>Dátum</span>
                </div>
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{editedTerm.termType.price} €</h4>
                  <span>Cena</span>
                </div>
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{editedTerm.duration} min.</h4>
                  <span>Dĺžka termínu</span>
                </div>
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{editedTerm.phoneNumber}</h4>
                  <span>Telefónne číslo</span>
                </div>
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{editedTerm.email}</h4>
                  <span>Email</span>
                </div>
                <div className="group col-md-6 col-lg-4 mb-2">
                  <h4>{editedTerm.firstName} {editedTerm.lastName}</h4>
                  <span>Meno a priezvisko</span>
                </div>
                {
                  editedTerm.userNotes !== ""
                    ? (
                      <div className="group col-md-12 mb-2">
                        <h4>{editedTerm.userNotes}</h4>
                        <span>Poznámka k termínu</span>
                      </div>
                    )
                    : null
                }
                {
                  isAdmin && (
                    <div className="group col-md-12 mb-2">
                      <h4>{editedTerm.adminNotes}</h4>
                      <span>Poznámky administrátora</span>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary me-2"
                onClick={() => this.setState({ showAlert: false })}
              >
                Zrušiť
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleConfirmSave}
              >
                Potvrdiť
              </button>
            </div>
          </div>
        )
        }
      </div>
    );
  }
}
