import React, { useState, useEffect } from "react";
import axios from "axios";
import configApi from "../../config";
import { TermReservationForm } from "../reservations/TermReservationForm";

const PatientTermsTable = ({ patient, onEditTerm, refreshTerms }) => {
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    fetchTerms();
  }, [refreshTerms]);


  const fetchTerms = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Terms/Patient/${patient.id}`,
        config
      );
      setTerms(response.data);
      setLoading(false); // Set loading state to false after fetching terms
    } catch (error) {
      console.log(error);
      setLoading(false); // Set loading state to false on error as well
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }

  return (
    <>
      <h3 className="mt-5">Termíny ({terms.length})</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Dátum</th>
            <th>Typ termínu</th>
            <th>Cena</th>
            <th>Prítomný</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {terms.map((term) => (
            <tr key={term.id}>
              <td>{formatDate(term.date)}</td>
              <td>{term.termType?.name}</td>
              <td>{term.termType?.price}</td>
              <td>{term.wasPresent ? "Áno" : "Nie"}</td>
              <td className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={() => onEditTerm(term.id)}
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PatientTermsTable;

