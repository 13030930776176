import React, { useState, useEffect } from "react";
import axios from "axios";
import CreateTermAutomat from "./CreateTermAutomat";
import config from "../../config";

const CreateTerm = () => {
  const [lengthOfTerm, setLengthOfTerm] = useState(60);
  const [isOccupied, setIsOccupied] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [patient, setPatient] = useState(null);
  const [termType, setTermType] = useState("");
  const [termTypes, setTermTypes] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    fetchTermTypes();
  }, []);

  const fetchTermTypes = async () => {
    try {
      const response = await axios.get(`${config.apiEndpoint}/api/TermTypes`);
      setTermTypes(response.data);
    } catch (error) {
      console.error("Failed to fetch term types:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const data = {
        date: new Date(date).toISOString(),
        duration: lengthOfTerm,
        isOccupied: isOccupied,
        isHidden: isHidden,
        patient: patient,
        termType: termTypes.find((obj) => obj.id === parseInt(termType)),
        phoneNumber: phoneNumber,
        email: email,
        firstName: firstName,
        lastName: lastName,
      };
      const response = await axios.post(`${config.apiEndpoint}/api/Terms`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })

      if (response.status === 201) {
        setSuccessMessage("Termín bol úspešne vytvorený!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }

      setDate(new Date());
      setLengthOfTerm(60);
      setIsOccupied(false);
      setPatient(null);
      setTermType("");
      setPhoneNumber("");
      setEmail("");
      setFirstName("");
      setLastName("");
    } catch (error) {
      setErrorMessage("Nepodarilo sa vytvoriť termín. Skúste to prosím znova.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      console.error("Failed to create term:", error);
    }
  };

  const handleDateChange = (e) => {
    const localDate = new Date(e.target.value);
    const now = new Date();
    if (isNaN(localDate.getTime()) || localDate < now) {
      setDate(new Date(now));
    } else {
      setDate(localDate);
    }
  };

  return (
    <div>
      <h2>Vytvoriť termín</h2>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <form onSubmit={handleSubmit} className="row g-3 mb-3">
        <div className="col-md-6">
          <label htmlFor="date" className="form-label">
            Dátum a čas
          </label>

          <input
            type="datetime-local"
            className="form-control"
            id="date"
            value={new Date(date.getTime() + 60 * 60000).toISOString().slice(0, 16)}
            onChange={handleDateChange}
            min={new Date().toISOString().slice(0, 16)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="amount" className="form-label">
            Dĺžka termínu (v minútach)
          </label>
          <input type="number" className="form-control" id="amount" value={lengthOfTerm} onChange={(e) => setLengthOfTerm(e.target.value)} />
        </div>
        <div className="col-md-6">
          <label htmlFor="termType" className="form-label">
            Typ termínu
          </label>
          <select
            className="form-select"
            id="termType"
            value={termType}
            onChange={(e) => setTermType(e.target.value)}
          >
            <option value="">Vyberte typ termínu</option>
            {termTypes.map((termType) => (
              <option key={termType.id} value={termType.id}>
                {termType.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <label htmlFor="isOccupied" className="form-label">
            Obsadenosť
          </label>
          <div className="form-check" id="isOccupied">
            <input
              className="form-check-input"
              type="checkbox"
              id="isOccupied"
              checked={isOccupied}
              onChange={(e) => setIsOccupied(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="isOccupied">
              Obsadené
            </label>
          </div>
        </div>
        <div className="col-md-3">
          <label htmlFor="isHidden" className="form-label">
            Viditeľnosť termínu
          </label>
          <div className="form-check" id="isHidden">
            <input
              className="form-check-input"
              type="checkbox"
              id="isHidden"
              checked={isHidden}
              onChange={(e) => setIsHidden(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="isHidden">
              Schovať termín
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="phoneNumber" className="form-label">
            Telefónne číslo
          </label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Tel. číslo"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="firstName" className="form-label">
            Meno
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Meno"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="lastName" className="form-label">
            Priezvisko
          </label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Priezvisko"
          />
        </div>

        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Vytvoriť termín
          </button>
        </div>
      </form>
      <CreateTermAutomat />
    </div>
  );
};

export default CreateTerm;
