import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    let navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        navigate('/login');
        window.location.reload();
    }, [navigate]);

    return null;  // This component doesn't render anything
};

export default Logout;
