import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import configApi from "../../config";
import PatientTermsTable from "./PatientTermsTable";
import { TermReservationForm } from "../reservations/TermReservationForm";
import Reservations from "../reservations/Reservations";

const PatientDetail = () => {
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);
  const [editingTermId, setEditingTermId] = useState(null);
  const [terms, setTerms] = useState([]);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    fetchPatient();
    fetchTerms();
  }, []);

  const fetchPatient = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Patients/${patientId}`,
        config
      );
      setPatient(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTerms = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Terms/Patient/${patientId}`,
        config
      );
      setTerms(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPatient({ ...patient, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(
        `${configApi.apiEndpoint}/api/Patients/${patientId}`,
        patient,
        config
      );
      console.log("Patient updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditTerm = (termId) => {
    setEditingTermId(termId);
  };

  const handleSaveTerm = (editedTerm) => {
    setEditingTermId(null);
    fetchTerms();
  };

  const handleCancelEdit = () => {
    setEditingTermId(null);
  };

  if (!patient) {
    return <div>Loading...</div>;
  }

  const handleRefreshTerms = async () => {
    fetchTerms();
  };


  return (
    <div className="container">
      <Link to="/user" className="btn btn-secondary mt-3 mb-3">
        Späť
      </Link>
      {editingTermId ? (
        <TermReservationForm
          term={terms.find((term) => term.id === editingTermId)}
          preFilledPatient={patient} // Pass the patient data here
          onSave={handleSaveTerm}
          onCancel={handleCancelEdit}
        />
      ) : (
        <>
          <h2 className="mb-4">Karta pacienta</h2>
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="firstName" className="form-label">
                  Meno:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={patient.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName" className="form-label">
                  Priezvisko:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={patient.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="phoneNumber" className="form-label">
                  Telefónne číslo:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={patient.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={patient.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label htmlFor="address" className="form-label">
                  Adresa:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={patient.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Uložiť
            </button>
          </form>

          <PatientTermsTable patient={patient} onEditTerm={handleEditTerm} refreshTerms={fetchTerms} />
          <Reservations preFilledPatient={patient} onTermSave={handleSaveTerm} />
        </>
      )}
    </div>
  );
};

export default PatientDetail;
