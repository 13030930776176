import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";

const TermTypesTable = () => {
  const [termTypes, setTermTypes] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedTermType, setSelectedTermType] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  useEffect(() => {
    const fetchTermTypes = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${config.apiEndpoint}/api/TermTypes`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTermTypes(response.data);
      } catch (error) {
        console.error("Failed to fetch term types:", error);
      }
    };

    fetchTermTypes();
  }, []);

  const handleCreate = () => {
    setSelectedTermType({});
  };

  const handleEdit = (termType) => {
    setSelectedTermType(termType);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedTermType) {
      if (selectedTermType.id) {
        await handleEditTermType(selectedTermType);
      } else {
        await handleAddTermType(selectedTermType);
      }
    }
  };

  const handleEditTermType = async (termType) => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${config.apiEndpoint}/api/TermTypes/${termType.id}`,
        termType,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const updatedTermTypes = termTypes.map((t) =>
        t.id === termType.id ? termType : t
      );
      setTermTypes(updatedTermTypes);
      setMessage("Typ termínu aktualizovaný.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Nepdoarilo sa aktualizovať.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleDeleteTermType = async (termTypeId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${config.apiEndpoint}/api/TermTypes/${termTypeId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const remainingTermTypes = termTypes.filter(
        (termType) => termType.id !== termTypeId
      );
      setTermTypes(remainingTermTypes);
      setMessage("Typ termínu úspešne odstránený.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Nepodarilo sa odstrániť.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleAddTermType = async (termType) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${config.apiEndpoint}/api/TermTypes`,
        termType,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTermTypes([...termTypes, response.data]);
      setMessage("Typ termínu vytvorený.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Nepodarilo sa vytvoriť typ termínu.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleCloseForm = () => {
    setSelectedTermType(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedTermType((prevTermType) => ({ ...prevTermType, [name]: value }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const displayedTermTypes = termTypes.filter(
    (termType) =>
      (termType.name?.toLowerCase()?.includes(search.toLowerCase()) ?? false) ||
      (termType.description?.toLowerCase()?.includes(search.toLowerCase()) ??
        false)
  );

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <button className="btn btn-primary" onClick={handleCreate}>
          Vytvoriť nový typ termínu
        </button>
        <input
          type="search"
          placeholder="Vyhladať"
          value={search}
          onChange={handleSearch}
          className="form-control w-25"
        />
      </div>
      {message && (
        <div
          className={`alert ${
            messageType === "success" ? "alert-success" : "alert-danger"
          }`}
        >
          {message}
        </div>
      )}
      {selectedTermType && (
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">
              {selectedTermType.id ? "Editovanie typu termínu" : "Vytvorenie nového typu termínu"}
            </h5>
            <button
              className="btn btn-outline-secondary rounded-circle btn-sm"
              onClick={handleCloseForm}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Názov</label>
                  <input
                    type="text"
                    name="name"
                    value={selectedTermType.name || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Opis</label>
                  <input
                    type="text"
                    name="description"
                    value={selectedTermType.description || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Dĺžka</label>
                  <input
                    type="number"
                    name="duration"
                    value={selectedTermType.duration || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Cena</label>
                  <input
                    type="number"
                    name="price"
                    value={selectedTermType.price || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <button className="btn btn-primary">
                {selectedTermType.id ? "Aktualizovať typ termínu" : "Vytvoriť typ termínu"}
              </button>
            </form>
          </div>
        </div>
      )}
      <table className="table table-striped mt-2">
        <thead>
          <tr>
            <th>Názov</th>
            <th>Opis</th>
            <th>Dĺžka</th>
            <th>Cena</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {displayedTermTypes.map((termType) => (
            <tr key={termType.id}>
              <td>{termType.name}</td>
              <td>{termType.description}</td>
              <td>{termType.duration}</td>
              <td>{termType.price}</td>
              <td className="d-flex gap-1 justify-content-end">
                <button
                  className="btn btn-sm btn-warning mr-2"
                  onClick={() => handleEdit(termType)}
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDeleteTermType(termType.id)}
                >
                  <i className="bi bi-trash-fill"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TermTypesTable;
