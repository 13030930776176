import React from 'react';
import "./WeekCalendar.css";
import ReservationForm from './ReservationForm';

class WeekCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            termBlocks: this.initializeTermBlocks(),
            selectedTerm: null,
        };
    }

    initializeTermBlocks() {
        const termBlocks = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(2023, 7, i + 15, Math.floor(Math.random() * 24 / 8) + 8, 0); // random hour, 0 minutes
            termBlocks.push({
                id: i + 1,
                date: date.toISOString(),
                duration: (Math.floor(Math.random() * 4) + 1) * 60,
            });
        }
        termBlocks.push({ id: 8, date: '2023-08-22T06:00:00.000Z', duration: 15 });
        termBlocks.push({ id: 8, date: '2023-08-21T12:00:00.000Z', duration: 90 });
        return termBlocks;
    }

    getCurrentWeek() {
        const week = [];
        const { currentDate } = this.state;

        const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1); // adjust to start week from Monday
        const startOfWeekDate = new Date(currentDate);
        startOfWeekDate.setDate(firstDayOfWeek);

        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeekDate.setDate(firstDayOfWeek + i));
            week.push(day);
        }
        return week;
    }


    goPrevWeek = () => {
        this.setState(prevState => {
            const prevWeek = new Date(prevState.currentDate.setDate(prevState.currentDate.getDate() - 7));
            return { currentDate: prevWeek };
        });
    }

    goNextWeek = () => {
        this.setState(prevState => {
            const nextWeek = new Date(prevState.currentDate.setDate(prevState.currentDate.getDate() + 7));
            return { currentDate: nextWeek };
        });
    }

    getMonthName(date) {
        return date.toLocaleDateString('sk-SK', { month: 'short' });
    }

    getYearMonth(date) {
        return date.toLocaleDateString('sk-SK', { year: 'numeric' });
    }

    handleDateChange = (event) => {
        this.setState({ currentDate: new Date(event.target.value) });
    }

    goToToday = () => {
        this.setState({ currentDate: new Date() });
    }

    findEarliest = (termBlocks) => {
        let earliestHour = new Date(termBlocks[0].date).getHours();
        let latestHour = new Date(termBlocks[0].date).getHours() + termBlocks[0].duration / 60;

        for (const block of termBlocks) {
            const startHour = new Date(block.date).getHours();
            const endHour = startHour + block.duration / 60;

            if (startHour < earliestHour) {
                earliestHour = startHour;
            }
            if (endHour > latestHour) {
                latestHour = endHour;
            }
        }
        return [earliestHour - 1, latestHour + 1];
    }


    getHoursInDay() {
        let [start, end] = this.findEarliest(this.state.termBlocks);
        const hours = [];
        for (let i = start; i <= end; i++) {
            hours.push(i);
        }
        return hours;
    }

    formatDateToInput(date) {
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // add 1 since months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    render() {
        const currentWeek = this.getCurrentWeek();
        const dayOptions = { weekday: 'long' };
        const dateOptions = { day: '2-digit', month: '2-digit' };

        const firstDayOfMonth = this.getMonthName(currentWeek[0]);
        const firstMonth = this.getYearMonth(currentWeek[0]);
        const lastDayOfMonth = this.getMonthName(currentWeek[currentWeek.length - 1]);
        const lastMonth = this.getYearMonth(currentWeek[currentWeek.length - 1]);

        let monthTitle = firstDayOfMonth;
        if (firstDayOfMonth !== lastDayOfMonth) {
            if (firstMonth !== lastMonth) {
                monthTitle += ' ' + firstMonth;
            }
            monthTitle += ' - ' + lastDayOfMonth + ' ' + lastMonth;
        } else {
            monthTitle += ' ' + firstMonth;
        }

        const earliestHour = this.findEarliest(this.state.termBlocks)[0];

        return (
            <div className='row'>
                <div className='col-12'>
                    <div className="d-flex align-items-center mb-3">
                        <h2>{monthTitle}</h2>
                        <div className="ms-auto d-inline-flex">
                            <input
                                type="date"
                                className="form-control me-2"
                                onChange={this.handleDateChange}
                                value={this.formatDateToInput(this.state.currentDate)}
                            />

                            <button className="btn btn-secondary me-2" onClick={this.goToToday}>Dnes</button>
                            <button className="btn btn-primary me-2" onClick={this.goPrevWeek}>
                                <i className="bi bi-chevron-left"></i>
                            </button>
                            <button className="btn btn-primary" onClick={this.goNextWeek}>
                                <i className="bi bi-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row position-relative overflow-auto p-0" style={{ maxHeight: 400 }}>
                        <div className='d-inline-flex flex position-sticky top-0 bg-light p-0 z-1'>
                            <div className=" border-end" style={{ width: 50, minWidth: 50 }}></div>
                            {currentWeek.map((day, index) =>
                                <div key={index} className='col border-end px-2' style={{ minWidth: 75 }}>
                                    {day.toLocaleDateString('sk-SK', dayOptions)}<br />
                                    <small className="text-secondary">{day.toLocaleDateString('sk-SK', dateOptions)}</small>
                                </div>
                            )}
                        </div>
                        <div className='d-flex p-0'>
                            <div className='d-flex flex-column p-0'>
                                {this.getHoursInDay().map((hour, index) =>
                                    <div key={index} className='d-inline-flex border-top' style={{ height: 60 }}>
                                        <div style={{ width: 50, minWidth: 50 }} className='text-end pe-2 border-end'>{hour}:00</div>
                                    </div>
                                )}
                            </div>
                            {currentWeek.map((day, index) =>
                                <div key={index} className="col border-end position-relative horizontal-lines" style={{ minWidth: 75 }}>
                                    {this.state.termBlocks.map((termBlock, blockIndex) => {
                                        const blockDate = new Date(termBlock.date);
                                        // Only render the term block if it matches the current day.
                                        if (blockDate.toLocaleDateString() !== day.toLocaleDateString()) {
                                            return null;
                                        }
                                        const top = (blockDate.getHours() - earliestHour) * 60 + blockDate.getMinutes(); // Top position in pixels.
                                        return (
                                            <div key={blockIndex} className='position-absolute free-block-term w-100 overflow-hidden rounded-1' style={{ top, height: termBlock.duration, zIndex: 0 }} onClick={() => this.setState({ selectedTerm: termBlock })}
                                            >
                                                <span>
                                                    {`#${blockIndex + 1} ` + `Volne`}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>

                            )}
                        </div>

                    </div>
                </div>

                <div className="col-12">
                    <ReservationForm
                        term={this.state.selectedTerm}
                        onCancel={() => {
                            this.setState({ selectedTerm: null, step: 1 });
                        }}
                    />

                </div>
            </div >

        )
    }
}

export default WeekCalendar;
