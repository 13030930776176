import React from "react";

const PatientStats = ({ patients }) => {
  // Calculate statistics
  const totalPatients = patients.length;

  return (
    <div>
      <h3>Patient Statistics</h3>
      <p>Total Patients: {totalPatients}</p>
    </div>
  );
};

export default PatientStats;
