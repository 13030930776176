import React, { Component } from "react";

export class CalendarCell extends Component {
  static displayName = CalendarCell.name;

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  // Define the hover event handlers
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  onCellClick = () => {
    const { date, onDateChange, disabled } = this.props;
    if (!disabled) {
      onDateChange(date);
    }
  };

  render() {
    const { activeDate } = this.props;
    const isActive = activeDate && activeDate.toDateString() === this.props.date.toDateString();
    const { isHovered } = this.state;
    const hoverClasses = isHovered ? "td-hover " : "";
    const activeClasses = isActive ? "td-active" : "";
    const disabled = this.props.disabled;
    const { value, termCount } = this.props;
    const isAdmin = this.props.isAdmin;
    const bulletCount = !isAdmin ? Math.min(termCount, 3) : termCount;

    return (
      <td
        className={`${this.props.today ? "td-today" : ""} + p-1 overflow-auto ${disabled ? "td-disabled" : ((this.props.hoverActive ? hoverClasses : "") + (activeClasses))} `}
        onClick={this.onCellClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={disabled ? { cursor: "default" } : null}
      >
        {this.props.today ? (
          <span className=" fs-6">{this.props.value}</span>
        ) : (
          <span className="">{this.props.value}</span>
        )}
        {isAdmin && bulletCount > -1 && (<span className="smallCount">{bulletCount}</span>)}
        {!isAdmin && bulletCount > 0 && (
          <div className="bullets">
            {Array.from({ length: bulletCount }, (_, i) => (
              <span key={i} className="calendarbullet"></span>
            ))}
          </div>
        )}
      </td>
    );
  }
}
