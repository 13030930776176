import React from 'react';
import { useLocation } from 'react-router-dom';
import TokenClearAlert from './TokenClearAlert';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ClearTermByHashPage() {
    let query = useQuery();

    const message = decodeURIComponent(query.get("message"));
    const success = query.get("success") === 'true' ? 'success' : 'danger';

    return <TokenClearAlert message={message} variant={success} />;
}

export default ClearTermByHashPage;
