import React, { useEffect, useState } from "react";
import axios from "axios";
import configApi from "../../config";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${configApi.apiEndpoint}/api/ApplicationUsers`,
          config
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get(
          `${configApi.apiEndpoint}/api/ApplicationUsers/roles`,
          config
        );
        setRoles(response.data);
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    };

    fetchUsers();
    fetchRoles();
  }, []);

  const handleEdit = (user) => {
    const userForEditing = { ...user };

    if (userForEditing.dateOfBirth) {
      const date = new Date(userForEditing.dateOfBirth);
      userForEditing.dateOfBirth = date.toISOString().slice(0, 10);
    }

    setSelectedUser(userForEditing);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedUser) {
      const user = {
        id: selectedUser.id,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        userName: selectedUser.userName,
        dateOfBirth: selectedUser.dateOfBirth,
        email: selectedUser.email,
        address: selectedUser.address,
        roles: selectedUser.roles,
        phoneNumber: selectedUser.phoneNumber,
        patient: selectedUser.patient,
      };

      const date = new Date(user.dateOfBirth);
      user.dateOfBirth = date.toISOString();

      if (user.roles && user.roles.length === 0) {
        user.roles = null;
      }

      if (user.id) {
        await handleEditUser(user);
      }
    }
  };

  const handleEditUser = async (user) => {
    try {
      console.log(user);
      const response = await axios.put(
        `${configApi.apiEndpoint}/api/ApplicationUsers/${user.id}`,
        user,
        config
      );

      const updatedUsers = users.map((u) => (u.id === user.id ? response.data : u));
      setUsers(updatedUsers);
      setMessage("User updated successfully.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Failed to update user");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {
        await axios.delete(`${configApi.apiEndpoint}/api/ApplicationUsers/${userId}`, config);
        const remainingUsers = users.filter((user) => user.id !== userId);
        setUsers(remainingUsers);
        setMessage('User deleted.');
        setMessageType('success');
        setTimeout(() => {
          setMessage(null);
          setMessageType(null);
        }, 3000);
      } catch (error) {
        setMessage('Failed to delete user.');
        setMessageType('error');
        setTimeout(() => {
          setMessage(null);
          setMessageType(null);
        }, 3000);
      }
    }
  };

  const handleCloseForm = () => {
    setSelectedUser(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      roles: value ? [value] : [],
    }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setSelectedUser((prevUser) => ({ ...prevUser, phoneNumber: value }));
  };

  const displayedUsers = users.filter(
    (user) =>
      (user.firstName?.toLowerCase()?.includes(search.toLowerCase()) ?? false) ||
      (user.lastName?.toLowerCase()?.includes(search.toLowerCase()) ?? false) ||
      (user.email?.toLowerCase()?.includes(search.toLowerCase()) ?? false)
  );

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <input
          type="search"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          className="form-control w-25 ms-auto"
        />
      </div>
      {message && (
        <div
          className={`alert ${messageType === "success" ? "alert-success" : "alert-danger"
            }`}
        >
          {message}
        </div>
      )}
      {selectedUser && (
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">
              Edit User
            </h5>
            <button
              className="btn btn-outline-secondary rounded-circle btn-sm"
              onClick={handleCloseForm}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={selectedUser.firstName || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={selectedUser.lastName || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={selectedUser.dateOfBirth || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={selectedUser.email || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={selectedUser.address || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Role</label>
                  <select
                    name="role"
                    value={selectedUser.roles != null ? selectedUser.roles[0] : ""}
                    onChange={handleRoleChange}
                    className="form-select"
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={selectedUser.phoneNumber || ""}
                    onChange={handlePhoneNumberChange}
                    className="form-control"
                  />
                </div>
              </div>
              <button className="btn btn-primary">
                {selectedUser.id ? "Update User" : "Create User"}
              </button>
            </form>
          </div>
        </div>
      )}
      <table className="table table-striped mt-2 table-hover">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
            <th>Date of Birth</th>
            <th>Email</th>
            <th>Address</th>
            <th>Patient</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {displayedUsers.map((user) => (
            <tr key={user.id} style={{ cursor: "pointer" }}>
              <td>
                {user.roles.includes("Admin") && (
                  <span style={{ color: "green" }}>&bull;</span>
                )}
              </td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.userName}</td>
              <td>
                {user.dateOfBirth
                  ? new Date(user.dateOfBirth).toLocaleDateString("en-US")
                  : null}
              </td>
              <td>{user.email}</td>
              <td>{user.address}</td>
              <td>{user.patient ? <i class="bi bi-check2"></i> : <i class="bi bi-x"></i>}</td>
              <td className="d-flex justify-content-end gap-1">
                <button
                  className="btn btn-sm btn-warning mr-2"
                  onClick={() => handleEdit(user)}
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDeleteUser(user.id)}
                >
                  <i className="bi bi-trash-fill"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
