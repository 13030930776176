import React, { useState, useEffect } from "react";
import { CalendarCell } from "./CallendarCell";
import config from "../config";

const Calendar = ({ value, onDateChange, activeDate, termCounts, fetchTermCounts }) => {
  const now = value || new Date();
  const [date, setDate] = useState({
    year: now.getFullYear(),
    month: now.getMonth(),
    newDate: now,
  });
  const [isAdmin, setIsAdmin] = useState(false);

  const handleDateChange = (newDate) => {
    setDate({
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      newDate: newDate,
    });
    onDateChange(newDate);
    fetchTermCounts(newDate.getFullYear(), newDate.getMonth() + 1);
  };

  const changeMonth = (offset) => {
    const newDate = new Date(date.year, date.month + offset);
    setDate({
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      newDate: newDate,
    });
    // Fetch term counts for the new month
    fetchTermCounts(newDate.getFullYear(), newDate.getMonth() + 1);
  };

  const today = () => {
    const now = new Date();
    const newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, now.getHours());
    setDate({
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      newDate: newDate
    });
    handleDateChange(newDate);
  };


  const isDateDisabled = (date) => {
    const now = new Date();
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    return date < today;
  };

  const getDaysBetween = (date1, date2) => {
    var diffInMs = Math.abs(date2.getTime() - date1.getTime());
    return diffInMs / (1000 * 60 * 60 * 24);
  };

  const getMonthData = (year, month) => {
    const startofMonth = new Date(year, month, 1);
    const endofMonth = new Date(year, month + 1, 0);

    const prefixDays = (startofMonth.getDay() + 6) % 7;
    const suffixDays = (7 - endofMonth.getDay()) % 7;

    const numberOfDays = getDaysBetween(endofMonth, startofMonth) + 1;
    return { prefixDays, numberOfDays, suffixDays };
  };

  const getDaysOfWeekInLocale = () => {
    var days = [];
    for (var i = 0; i <= 6; i++) {
      var date = new Date(2022, 0, i + 3); // Use any week in any year.
      var longDayName = date.toLocaleDateString("sk-SK", { weekday: "long" });
      var shortDayName = date.toLocaleDateString("sk-SK", { weekday: "short" });
      days.push({ long: longDayName, short: shortDayName });
    }
    return days;
  };

  const getMonthYearInLocale = (year, month) => {
    const date = new Date(year, month);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleString("sk-SK", options);
  };

  const prevMonth = () => changeMonth(-1);
  const nextMonth = () => changeMonth(1);

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const checkAdminRole = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setIsAdmin(false);
    } else {
      const apiUrl = `${config.apiEndpoint}/api/account/CheckAdminRole`;

      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          setIsAdmin(data.isAdmin);
        })
        .catch((error) => {
          setIsAdmin(false);
        });
    }
  };

  useEffect(() => {
    checkAdminRole();
  }, []);

  const { prefixDays, numberOfDays, suffixDays } = getMonthData(
    date.year,
    date.month
  );

  const days = [
    ...Array.from({ length: prefixDays }, (_, i) => i + 1).map(
      (_, index) => {
        return <CalendarCell key={"prefix-" + index} hoverActive={false} disabled />;
      }
    ),
    ...Array.from({ length: numberOfDays }, (_, i) => i + 1).map((_, index) => {
      const isToday =
        index + 1 === new Date().getDate() &&
        date.newDate.getMonth() === new Date().getMonth() &&
        date.newDate.getFullYear() === new Date().getFullYear();
      const cellDate = new Date(
        date.newDate.getFullYear(),
        date.newDate.getMonth(),
        index + 1,
        12
      );

      const isFutureDate = cellDate > new Date(new Date().setHours(48, 59, 59, 999)); // Check if the cell date is after today
      const termCount = termCounts.find(tc => {
        const termDate = new Date(tc.date);
        return termDate.getUTCFullYear() === cellDate.getFullYear() &&
          termDate.getUTCMonth() === cellDate.getMonth() &&
          termDate.getUTCDate() === cellDate.getDate();
      })?.count || 0;
      const showTermCount = isAdmin || isFutureDate;
      return (
        <CalendarCell
          key={"day-" + index}
          value={index + 1}
          hoverActive={true}
          today={isToday}
          date={cellDate}
          activeDate={activeDate}
          onDateChange={onDateChange}
          termCount={showTermCount ? termCount : 0}
          disabled={isAdmin ? false : isDateDisabled(cellDate)}
          isAdmin={isAdmin}
        />
      );
    }),
    ...Array.from({ length: suffixDays }, (_, i) => i + 1).map((_, index) => {
      return <CalendarCell key={"suffix-" + index} hoverActive={false} disabled />;
    }),
  ];
  const weeks = chunkArray(days, 7);

  useEffect(() => {
    if (value) {
      setDate({
        year: value.getFullYear(),
        month: value.getMonth(),
        newDate: value,
      });
    }
  }, [value]);

  return (
    <div className="">
      <div>
        <div className="d-flex justify-content-between pb-2">
          <button
            type="button"
            className="button-primary"
            onClick={today}
          >
            Dnes
          </button>

          <span className="fs-4 fw-bold text-capitalize">
            {date.newDate.toLocaleString("sk-SK", { month: "long" })}{" "}
            {date.newDate.getFullYear()}
          </span>

          <div className="d-flex">
            <button
              className="button-primary "
              onClick={prevMonth}
            >
              <i className="bi bi-chevron-left"></i>
            </button>

            <button
              className="button-primary"
              onClick={nextMonth}
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>

        <table className="table w-100">
          <thead>
            <tr>
              {getDaysOfWeekInLocale().map((item, index) => (
                <th
                  key={index}
                  className="p-2  h-100 text-center text-capitalize"
                >
                  <span className="d-none d-lg-block">{item.long}</span>
                  <span className="d-block d-lg-none">{item.short}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, index) => (
              <tr key={"week-" + index} className="text-center h-100">
                {week}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
