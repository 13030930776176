import React from 'react';

const TokenClearAlert = ({ message, variant }) => {
    return (
        <div className={`alert alert-${variant}`} role="alert">
            {message}
        </div>
    );
};

export default TokenClearAlert;
