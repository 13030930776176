import React from 'react';

const Message = ({ message, onClose, handleAddToGoogleCalendar }) => (
  message && (
    <div
      className={`alert alert-${message.type} alert-dismissible`}
      role="alert"
    >
      {message.text}
      {message.type === "success" && handleAddToGoogleCalendar !== null &&
        <button
          type="button"
          className="btn btn-primary d-block mt-2"
          onClick={handleAddToGoogleCalendar}
        >
          <img
            src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
            alt="Google Logo"
            style={{ width: '20px', marginRight: '5px' }}
          />
          Pridať do Google kalendára
        </button>
      }
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={onClose}
      ></button>
    </div>
  )
);

export default Message;
