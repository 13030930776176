import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [sex, setSex] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [gdprAgreed, setGdprAgreed] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure GDPR agreement and password confirmation
        if (!gdprAgreed) {
            setError('Musíte súhlasiť so spracovaním osobných údajov podľa GDPR.');
            return;
        }
        if (password !== confirmPassword) {
            setError('Heslá sa nezhodujú.');
            return;
        }

        try {
            const registerResponse = await axios.post(`${config.apiEndpoint}/api/account/register`, {
                username,
                email,
                password,
                firstName,
                lastName,
                dateOfBirth,
                sex
            });

            if (registerResponse.status === 200) {
                const loginResponse = await axios.post(`${config.apiEndpoint}/api/account/login`, {
                    username,
                    password
                });
                // Store the login token and other necessary details
                localStorage.setItem('token', loginResponse.data.token);
                const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000; // 3 hours from now
                localStorage.setItem("username", username.trim()); // Save the trimmed username
                localStorage.setItem("tokenExpiration", expirationTime);


                setError('');
                navigate('/user');
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
            const errorMessage = error.response?.data || 'Something went wrong. Please try again!';
            setError(errorMessage);
        }
    };



    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <h1 className="text-left">Registrovať</h1>
                    <form onSubmit={handleSubmit} className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="firstName" className="form-label">Krstné meno</label>
                            <input type="text" className="form-control" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Krstné meno" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="lastName" className="form-label">Priezvisko</label>
                            <input type="text" className="form-control" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Priezvisko" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="username" className="form-label">Prihlasovacie meno</label>
                            <input type="text" className="form-control" id="username" value={username} onChange={e => setUsername(e.target.value)} placeholder="Prihlasovacie meno" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="password" className="form-label">Heslo</label>
                            <input type="password" className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Heslo" required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Potvrďte heslo</label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                placeholder="Potvrďte heslo"
                                required
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="dateOfBirth" className="form-label">Dátum narodenia</label>
                            <input type="date" className="form-control" id="dateOfBirth" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Pohlavie</label>
                            <div className='d-flex gap-3'>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sex"
                                        id="male"
                                        value="Male"
                                        checked={sex === 'Male'}
                                        onChange={e => setSex(e.target.value)}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="male">Muž</label>
                                </div>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sex"
                                        id="female"
                                        value="Female"
                                        checked={sex === 'Female'}
                                        onChange={e => setSex(e.target.value)}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="female">Žena</label>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="gdprAgreement"
                                    checked={gdprAgreed}
                                    onChange={e => setGdprAgreed(e.target.checked)}
                                />
                                <label htmlFor="gdprAgreement" className="form-check-label">
                                    Súhlasím so spracovaním mojich osobných údajov v súlade s GDPR.
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary" disabled={!gdprAgreed}>
                                Registrovať sa
                            </button>
                        </div>
                    </form>
                    {error && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {error}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Register;
