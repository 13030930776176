import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UserTermsTable from './UserTermsTable';
import UserProfileSettings from './Settings';
import Reservations from '../reservations/Reservations';
import CreateTerm from '../admin/CreateTerm';
import PatientsTable from '../admin/PatientsTable';
import TermTypesTable from '../admin/TermTypesTable';
import configApi from '../../config';
import UsersTable from '../admin/UsersTable';
import MainStatsComponent from '../Stats/MainStats';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../shared/LoadingSpinner';

const MyProfile = () => {
  const [user, setUser] = useState(null);
  const [selected, setSelected] = useState('settings');
  const [navigationItems, setNavigationItems] = useState([]);
  const [userTerms, setUserTerms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      try {
        const response = await axios.get(`${configApi.apiEndpoint}/api/account/profile`, config);
        setUser(response.data);

      } catch (error) {
        console.error('Failed to fetch profile:', error);
        navigate("/login");
      }
    };

    fetchProfile();
  }, [navigate]);

  useEffect(() => {
    const items = [
      { name: 'reservations', label: 'Rezervácie', component: Reservations },
      { name: 'users', label: 'Moje termíny', component: () => <UserTermsTable userId={user.id} /> },
      { name: 'settings', label: 'Nastavenia', component: UserProfileSettings },
    ];

    if (user?.roles && user.roles.includes('Admin')) {
      items.push({ name: 'patientsTable', label: 'Pacienti', component: PatientsTable });
      items.push({ name: 'createterm', label: 'Termíny', component: CreateTerm });
      items.push({ name: 'usersTable', label: 'Používatelia', component: UsersTable });
      items.push({ name: 'termtypestable', label: 'Typy termínov', component: TermTypesTable });
      items.push({ name: 'mainStatsComponent', label: 'Štatistiky', component: MainStatsComponent });
    }

    setNavigationItems(items);
  }, [user]);

  if (!user) {
    return (
      <LoadingSpinner />
    )
  }

  const handleClick = (name) => {
    setSelected(name);
  };

  const SelectedComponent = navigationItems.find(item => item.name === selected)?.component;

  return (
    <div>
      <h1>Vitaj, {user.firstName == null && user.lastName == null ? user.username : `${user.firstName} ${user.lastName}`}!</h1>
      <h2>Email: {user.email}</h2>
      {(user?.roles && user.roles.includes('Admin')) ? <h3>Admin</h3> : null}
      <nav className="my-3" style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <ul className="d-flex justify-content-start gap-2 list-unstyled">
          {navigationItems.map((item) => (
            <li key={item.name} style={{ whiteSpace: 'nowrap' }}>
              <Link
                to="#"
                name={item.name}
                onClick={() => handleClick(item.name)}
                className={
                  selected === item.name ? 'btn btn-primary' : 'btn btn-outline-primary'
                }
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      <div id="divToRenderProfileComponents">
        {SelectedComponent && <SelectedComponent user={user} />}
      </div>
    </div>
  );
};

export default MyProfile;
