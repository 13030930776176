import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const OccupiedTermsChart = ({ terms }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const processData = () => {
      const sumByMonth = {};

      terms.forEach((term) => {
        if (term.wasPresent && term.termType != null) {
          const month = term.date.substring(0, 7); // Extract the year and month (YYYY-MM) from the date
          if (!sumByMonth[month]) {
            sumByMonth[month] = term.termType?.price;
          } else {
            sumByMonth[month] += term.termType?.price;
          }
        }
      });
      return sumByMonth;
    };

    const renderChart = (sumByMonth) => {
      const chartLabels = Object.keys(sumByMonth);
      const chartData = chartLabels.map((month) => sumByMonth[month]);

      const ctx = chartRef.current.getContext('2d');

      new Chart(ctx, {
        type: 'line', // Use line chart instead of bar chart
        data: {
          labels: chartLabels,
          datasets: [
            {
              label: 'Sum of Prices',
              data: chartData,
              fill: false, // Remove fill color for line chart
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Sum of Prices',
              },
            },
            x: {
                offset: true, // Add offset to the line chart
              },
          },
          layout: {
            padding: {
              left: 20, // Set the desired margin from the left side
              right: 20, // Set the desired margin from the right side
            },
          },
        },
      });
    };

    const sumByMonth = processData();
    renderChart(sumByMonth);
  }, [terms]);

  return (
    <div>
      <h2>Occupied Terms Chart</h2>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default OccupiedTermsChart;
