import React, { useState } from "react";
import axios from "axios";
import config from "../../config";

const CreateTermAutomat = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const currentDate = new Date();
  const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

  const defaultStartTime = "08:00";
  const defaultEndTime = "17:00";
  const durationStepMinutes = 60;

  const formattedDate = nextDate.toISOString().slice(0, 10);
  const formattedStartTime = defaultStartTime;
  const formattedEndTime = defaultEndTime;

  const [startDate, setStartDate] = useState(
    `${formattedDate}T${formattedStartTime}`
  );
  const [endDate, setEndDate] = useState(
    `${formattedDate}T${formattedEndTime}`
  );
  const [durationStep, setDurationStep] = useState(durationStepMinutes);
  const [termsToBeGenerated, setTermsToBeGenerated] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (new Date(startDate) > new Date(endDate)) {
      setErrorMessage("End date/time should be greater than Start date/time");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const start = new Date(startDate);
      const end = new Date(endDate);
      const durationInMinutes = parseInt(durationStep);
      const numOfTerms = Math.floor(
        (end - start) / (durationInMinutes * 60 * 1000)
      );

      const terms = [];
      let currentDateTime = new Date(start);
      for (let i = 0; i < numOfTerms; i++) {
        terms.push(new Date(currentDateTime));
        currentDateTime = new Date(
          currentDateTime.getTime() + durationInMinutes * 60 * 1000
        );
        if (currentDateTime > end) {
          break;
        }
      }

      setTermsToBeGenerated(terms);
      setSuccessMessage(`Počet termínov, ktoré sa vytvoria: ${terms.length}`);
    } catch (error) {
      setErrorMessage(
        "Nepodarilo sa, pravdepodobne sú v týchto časoch definované iné termíny."
      );
      console.error("Failed to create terms:", error);
    }
  };

  const handleConfirmation = async () => {
    try {
      const token = localStorage.getItem("token");
      const createTermPromises = termsToBeGenerated.map((term) => {
        const data = {
          date: term.toISOString(),
          isOccupied: false,
          duration: durationStep,
        };

        return axios.post(`${config.apiEndpoint}/api/Terms`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
      });

      await Promise.all(createTermPromises);

      setTermsToBeGenerated([]);
      setSuccessMessage("Termíny úspešne vytvorené!");
    } catch (error) {
      setErrorMessage(
        "Nepodarilo sa, pravdepodobne sú v týchto časoch definované iné termíny."
      );
      console.error("Failed to create terms:", error);
    }
  };

  const handleConfirmationDismiss = () => {
    setTermsToBeGenerated([]);
    setSuccessMessage("");
  };

  const handleStartDateChange = (e) => {
    const localDate = new Date(e.target.value);
    const now = new Date();

    if (isNaN(localDate.getTime()) || localDate < now) {
      setStartDate(now.toISOString().slice(0, 16));
    } else {
      setStartDate(localDate.toISOString().slice(0, 16));
    }

    const newEndDate = new Date(localDate.getTime() + 480 * 60 * 1000);
    if (newEndDate > new Date(endDate)) {
      setEndDate(newEndDate.toISOString().slice(0, 16));
    }
  };

  const handleEndDateChange = (e) => {
    const localDate = new Date(e.target.value);
    const startDateObj = new Date(startDate);

    if (localDate <= startDateObj) {
      const newEndDate = new Date(startDateObj.getTime() + 60 * 60 * 1000);
      setEndDate(newEndDate.toISOString().slice(0, 16));
    } else {
      setEndDate(localDate.toISOString().slice(0, 16));
    }
  };

  return (
    <div>
      <h2>Vytvoriť termíny (Automaticky)</h2>
      {successMessage && (
        <div className="alert alert-success alert-dismissible" role="alert">
          {successMessage}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleConfirmationDismiss}
          ></button>
        </div>
      )}
      {termsToBeGenerated.length > 0 && (
        <div className="alert alert-info" role="alert">
          Termíny, ktoré sa majú vytvoriť:
          <ul>
            {termsToBeGenerated.map((term, index) => (
              <li key={index}>
                <b>
                  {term.toLocaleTimeString("sk-SK", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                </b>
                {term.toLocaleDateString("sk-SK", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}{" "}
                {term.toLocaleDateString("sk-SK", {
                  weekday: "long",
                })}{" ("}
                {durationStep}{"min)"}
              </li>
            ))}
          </ul>
          <button className="btn btn-primary" onClick={handleConfirmation}>
            Potvrdiť vytvorenie termínov
          </button>
        </div>
      )}

      {errorMessage && (
        <div className="alert alert-danger alert-dismissible" role="alert">
          {errorMessage}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setErrorMessage("")}
          ></button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="row g-3">
        <div className="col-md-6">
          <label htmlFor="startDate" className="form-label">
            Začiatok termínov
          </label>
          <input
            type="datetime-local"
            className="form-control"
            id="startDate"
            value={startDate}
            min={new Date().toISOString().slice(0, 16)}
            onChange={handleStartDateChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="endDate" className="form-label">
            Koniec termínov
          </label>
          <input
            type="datetime-local"
            className="form-control"
            id="endDate"
            value={endDate}
            min={startDate}
            onChange={handleEndDateChange}
            required
            disabled={new Date(startDate) > new Date(endDate)}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="durationStep" className="form-label">
            Vytvoriť termín každých (?) minút
          </label>
          <input
            type="number"
            className="form-control"
            id="durationStep"
            value={durationStep}
            min="1"
            onChange={(e) => setDurationStep(parseInt(e.target.value))}
            required
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Generovať termíny
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTermAutomat;
