import { useEffect, useState } from 'react';
import config from "../../config";

const useAdminCheck = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const apiUrl = `${config.apiEndpoint}/api/account/CheckAdminRole`;

      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          setIsAdmin(data.isAdmin);
        })
        .catch((error) => {
          setIsAdmin(false);
        });
    }
  }, []);

  return isAdmin;
};

export default useAdminCheck;
